import React from 'react';
import "./style.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          © 2025 Mensch und Maschine - &nbsp;
          <a href="https://www.mum.de/unternehmen/impressum" className="footer-link">
            Impressum
          </a>
          &nbsp;-&nbsp;
          <a href="https://www.mum.de/unternehmen/datenschutz" className="footer-link">
            Datenschutz
          </a>
          &nbsp;-&nbsp;
          <a href="https://www.mum.de/download-portal##" className="footer-link">
            Cookie Consent Settings
          </a>
          &nbsp;-&nbsp;
          <a href="https://www.mum.de/unternehmen/agb" className="footer-link">
            AGB
          </a>
        </p>
      </div>
    </footer>
  );
}