import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config.js";
import Authentication from "../Authentication";

const RequireAuth = ({ children, setUserLicenses }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const auth = new Authentication();
      try {
        const idTokenPayload = await auth.login();
        if (idTokenPayload) {
          const email = idTokenPayload.email;
          const session = sessionStorage.getItem("idToken");
          const response = await axios.get(
            `${config.mumapapi.invokeUrl}/UserLicenses?email=${encodeURIComponent(email)}`,
            {
              headers: {
                Authorization: `Bearer ${session}`,
              },
            }
          );
          const { licenses } = response.data;
          setUserLicenses(licenses);

          const hasGeneralAccess = licenses.includes("Download Portal General Access");
          
          const hasCustomXLicense = licenses.includes("Custom X");  
          const haseXsLicense = licenses.includes("eXs");
          const hasGinfoLicense = licenses.includes("G-Info");
          const hasMapEditLicense = licenses.includes("Map Edit");
          const hasACADToolboxLicense = licenses.includes("ACAD Toolbox");
          const hasBimBoosterLicense = licenses.includes("Bim Booster");
          const hasMaterialBrowserLicense = licenses.includes("Material Browser");
          const hasMultitoolLicense = licenses.includes("Multitool");
          const hasPDMBoosterLicense = licenses.includes("PDM Booster");
          const hasPraxispaketStahlbauLicense = licenses.includes("Praxispaket Stahlbau");
          const hasQTOBoosterLicense = licenses.includes("QTO Booster 8")
          const hasSteelworkLicense = licenses.includes("Steelwork");

          const hasValidLicense = hasGeneralAccess || hasCustomXLicense || haseXsLicense || hasGinfoLicense || hasMapEditLicense || 
          hasACADToolboxLicense || hasBimBoosterLicense || hasMaterialBrowserLicense || hasMultitoolLicense ||
          hasPDMBoosterLicense || hasPraxispaketStahlbauLicense || hasQTOBoosterLicense || hasSteelworkLicense;
          setIsLoading(false);

          if (!hasValidLicense) {
            return;
          }
        } else {
          setIsLoading(false);
          setUserLicenses(["Download Portal General Access"]);
        }
      } catch (error) {
        setIsLoading(false);
        setUserLicenses(["Download Portal General Access"]);
      }
    };

    checkUserAuth();
  }, [setUserLicenses]);

  return (
    <>
      {!isLoading && children}
    </>
  );
};

export default RequireAuth;
